import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Performance from './components/Performance';
import Technology from './components/Technology';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Expertise from './components/Expertise';

const HomePage = () => (
  <>
    <Hero />
    <Performance />
    <Technology />
    <About />
    <Contact />
  </>
);

const App = () => {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-slate-50 relative">
        <Navbar />
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/expertise" element={<Expertise />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;