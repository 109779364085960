import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const ExpertisePage = () => {
  return (
    <div className="min-h-screen bg-slate-50 pt-16">
      <div className="max-w-6xl mx-auto px-4">
        <Link 
          to="/" 
          className="inline-flex items-center text-indigo-600 hover:text-indigo-700 mb-8"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Home
        </Link>

        <div className="bg-white rounded-lg shadow-sm p-8 border border-slate-200">
          <h1 className="text-3xl font-bold text-indigo-900 mb-8">
            Professional Journey & Expertise
          </h1>

          <div className="space-y-12">
            {/* Timeline Image */}
            <div className="rounded-lg overflow-hidden border border-slate-200">
              <img
                src="/timeline.png"
                alt="Professional Timeline"
                className="w-full object-contain"
              />
            </div>

            {/* Additional sections can be added here */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-indigo-900">
                  AI & Technology Background
                </h2>
                <p className="text-slate-600">
                  Started in AI research at Boeing in 1989, developing neural networks
                  and advanced flight control systems. This led to patent-worthy 
                  innovations in AI drone technology.
                </p>
              </div>

              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-indigo-900">
                  Trading & Market Analysis
                </h2>
                <p className="text-slate-600">
                  Successfully applied technical analysis in stock trading throughout
                  the 1990s, later developing AI-driven market prediction software
                  and launching a high-ROI stock newsletter.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertisePage;